import React from "react";
import AppCard from "../components/AppCard"
import Footer from "../components/Footer"

export default function Home() {
  return (
    <div>
      <div className="min-h-screen bg-gray-50 py-4 sm:py-6 flex flex-col justify-center">
        <div className="hero-section">
          <div className="container mx-auto px-8 sm:px-48">
            <h1 className="lead-text text-7xl sm:text-8xl sm:leading-14">
              We are <span className="branding-text font-bold">humanz</span>
            </h1>
            <p className="info-text md:text-2xl text-gray-400">we build interactive web/mobile apps!</p>
          </div>
        </div>
        <div className="normal-section mt-24">
          <div className="container mx-auto px-8 sm:px-48">
            <h5 className="md:text-xl font-bold text-gray-500 uppercase">Our Apps</h5>
            <div className="flex mt-1">
              <AppCard
                imgUrl="/tambola-logo.svg"
                imgAlt="Tambola.fun Logo"
                title="Tambola.fun"
                description="Play tambola remotely with your friends &amp; family online."
                link="https://tambola.fun/"
              />
              <div className="app-card"></div>
              <div className="app-card"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
