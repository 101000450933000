import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline"

export default function AppCard(props) {
  return (
    <div className="app-card relative py-3">
        {/* <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl"></div> */}
        <div className="relative bg-white shadow-md hover:shadow-lg transition-all rounded-xl sm:rounded-2xl p-4 sm:p-8 mt-8 sm:mt-0">
            <div className="flex items-start mx-auto flex-col sm:flex-row sm:items-center">
                <div className="-mt-14 sm:mt-0">
                    <img src={props.imgUrl} alt={props.imgAlt} className="w-20 max-w-2xl" />
                </div>
                <div className="flex-grow pl-4 text-gray-600">
                    <h4 className="md:text-2xl font-bold">{props.title}</h4>
                    <p className="font-thin mt-1 text-gray-500">{props.description}</p>
                    <div className="mt-3">
                        <a className="link mr-1" href={props.link} target="_blank">Know more</a><ExternalLinkIcon className="inline-block h-5 w-5 text-gray-300" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}